import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller {

  static targets = ["element"]; 

  connect(){
    Array.from(this.elementTargets).forEach((element) => {
      const max = 99; 
      let current = 0; 
      setInterval(() => {
        current = current < max ? current + 1 : 0
        element.innerHTML = current < 10 ? 0 + current.toString() : current; 
      }, 100);
    });
  }

}
