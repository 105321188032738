import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {

  connect(){
    if(!document.getElementById('marker-highlight-svg')){
      document.body.insertAdjacentHTML('afterbegin', this._svg())
    }
    const styles = 'marker-highlight inline'
    const targets = document.querySelectorAll('mark')
    Array.from(targets).map(el => el.setAttribute('class', styles))
  }

  _svg(){
    return `<svg id="marker-highlight-svg" xmlns="//www.w3.org/2000/svg" version="1.1" class="svg-filters" style="display:none;">
      <defs>
        <filter id="marker-shape">
          <feTurbulence type="fractalNoise" baseFrequency="0 0.15" numOctaves="1" result="warp" />
          <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="50" in="SourceGraphic" in2="warp" />
        </filter>
      </defs>
    </svg>`
  }

}



