import { Controller } from '@hotwired/stimulus'; 
import { onDocumentInteracted, insertScript, insertStylesheet } from '~/src/utils'; 
import Plyr from 'plyr/dist/plyr.js'; 
import 'plyr/dist/plyr.css'; 

export default class extends Controller {

  static get targets() {
    return ['player', 'placeholder']; 
  }

  connect() {
    window.players = window.players || []
    this.playerOptions = JSON.parse(this.element.dataset.playerOptions);
    this._loadPlayer();
  }

  disconnect() {
    if(this.player){
      this.player.destroy();
    }
  }

  _loadPlayer(){
    if(this.hasPlayerTarget){
      const css = 'https://cdn.plyr.io/3.7.3/plyr.css'; 
      insertStylesheet(css); 
      import('plyr/dist/plyr.js').then(module => { 
        const Plyr = module.default; 
        const defaults = {
          hd: true,
          controls: ['play-large', 'mute', 'volume'],
          clickToPlay: true,
          loop: { active: true },
          vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false }, 
          youtube: { modestbranding: 1, autohide: 1, showinfo: 0, controls: 0, iv_load_policy: 3 }
        }; 
        const options = { ...defaults, ...this.playerOptions };
        const player = new Plyr(this.playerTarget, options);
        if(options.volume){ player.volume = options.volume; }
        if(options.autoplay){ this.playerTarget.setAttribute('autoplay', 'autoplay') }
        if(options.loop){ this.playerTarget.setAttribute('loop', 'loop') }
        if(options.muted){ this.playerTarget.setAttribute('muted', 'muted') }
        if(options.autoplay){
          player.play(); 
        }
      }); 
      // const js = 'https://cdn.plyr.io/3.6.12/plyr.polyfilled.js'
      // const container = document.querySelector('[data-scripts]'); 
      // lazyload from data-src
      // if(this.hasIframeTarget && !this.iframeTarget.getAttribute('src')){
      //   this.iframeTarget.setAttribute('src', this.iframeTarget.getAttribute('data-placeholder'))
      // }
    }
  }

}
