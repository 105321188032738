export const isFalsy = (statement) => {
  return !this._isTruthy(statement); 
};

export const isTruthy = (statement) => {
  return parseInt(statement, 10) === 1 || statement === 'true' || statement === true; 
};

export const fireEvent = (name, callback, options) => {
  options = options || {}; 
  const event = new CustomEvent(name, options); 
  const id = `event:${name}@${event.timeStamp}`; 

  if(!window[id]){
    if(callback){ document.addEventListener(name, callback) }
    document.dispatchEvent(event);
    window[id] = true; 
  }
};

export const onDocumentInteracted = (callback) => {
  window.firedCallbacks = window.firedCallbacks || [];
  if(document.body.dataset.behaviorFlash != ''){
    callback();
  } else {
    ['scroll', 'click', 'mousemove', 'touchstart', 'turbo:render'].forEach((type, i) => {
      document.addEventListener(type, () => {
        if(!window.firedCallbacks.includes(callback)){
          callback()
          window.firedCallbacks.push(callback)
        } else {
          // console.log('🤓 already fired !')
        }
      }, { once: true });
    });
  }
};

export const insertStylesheet = (source) => {
  window.loadedStylesheets = window.loadedStylesheets || {}
  if(!window.loadedStylesheets[source]){
    const modern = document.createElement('link');
    modern.setAttribute('rel', 'preload');
    modern.setAttribute('type', 'text/css');
    modern.setAttribute('as', 'style');
    modern.setAttribute('href', source);
    const legacy = document.createElement('link');
    legacy.setAttribute('rel', 'stylesheet');
    legacy.setAttribute('media', 'print');
    legacy.setAttribute('onload', 'this.media="all"');
    legacy.setAttribute('href', source);
    document.head.appendChild(modern);
    document.head.appendChild(legacy);
    window.loadedStylesheets[source] = true; 
  }
}; 

export const insertScript = (parent, source, callbackOrEventName) => {
  window.loadedScripts = window.loadedScripts || {}
  if(!window.loadedScripts[source]){
    let script = document.createElement('script');
    let prior = document.getElementsByTagName('script')[0];
    script.async = 1;
    script.onload = script.onreadystatechange = function( _, isAbort){
      if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
        script.onload = script.onreadystatechange = null;
        script = undefined;
        if(typeof callbackOrEventName === 'string'){
          const event = new CustomEvent(`${callbackOrEventName}:ready`, {})
          document.dispatchEvent(event);
        } else {
          if(!isAbort && callbackOrEventName) setTimeout(callbackOrEventName, 200);
        }
      }
    };
    script.src = source;
    parent.appendChild(script)
    window.loadedScripts[source] = true; 
  } else {
    if(typeof callbackOrEventName === 'string'){
      setTimeout(() => {
        const event = new CustomEvent(`${callbackOrEventName}:ready`, {})
        document.dispatchEvent(event);
      }, 100); 
    } else {
      if(callbackOrEventName) { callbackOrEventName() }
    }
  }
}

