import { Controller } from "@hotwired/stimulus"; 
import Typewriter from 'typewriter-effect/dist/core';


export default class extends Controller {

  static targets = ['element']
  static values = { 
    words: String
  }

  connect(){
    Array.from(this.elementTargets).forEach((element) => {

      console.log(element); 
      const words = element.dataset.typewriterWords.split(','); 

      const typewriter = new Typewriter(element, {
        strings: words,
        autoStart: true,
        loop: true,
        delay: 100,
        deleteSpeed: 100,
        pauseDelay: 500,
        pauseOnHover: true,
        startDelay: 1000,
        onComplete: (self) => {
          // self.start();
        }
      });
    }); 
  }

}


