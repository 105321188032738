import { Turbo } from '@hotwired/turbo-rails';
import { registerControllers } from 'stimulus-vite-helpers';
Turbo.start();

import '@lottiefiles/lottie-player';
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import { Application } from "@hotwired/stimulus";
const application = Application.start();
application.debug = false;
window.Stimulus = application;

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading";
// eagerLoadControllersFrom("controllers", application);


const controllers  = import.meta.glob('../**/*_controller.js', { eager: true});
const components = import.meta.glob('../../components/**/*_controller.js', { eager: true });

registerControllers(application, {...controllers, ...components});
