import { Controller } from '@hotwired/stimulus';
import LazyLoad from 'vanilla-lazyload';
import SmoothScroll  from 'smooth-scroll';
import { insertScript, onDocumentInteracted, fireEvent } from '../src/utils';

export default class extends Controller {

  connect(){
    this.props = JSON.parse(this.element.dataset.behaviorProps);

    if(this.props.GOOGLE_ANALYTICS.ID || this.props.FACEBOOK_PIXEL.ID){
      this._loadCookieConsent();
    }
    this._handleSmoothScroll();
    this._handleLazyLoad();
  }

  _handleSmoothScroll(){
    const scroll = new SmoothScroll('a[href*="#"]');
  }

  _handleLazyLoad(){
    new LazyLoad({
      threshold: 200,
      elements_selector: '[data-src],[data-srcset],iframe,.lazy',
      callback_loaded: function(e){},
      callback_enter: function(element, o, a, b){
        console.log(element.getAttribute('data-src'));
        if ('LOTTIE-PLAYER' === element.tagName){
          element.src = element.getAttribute('data-src');
          element.load(element.src);
        }
      },
    });
  }

  _loadAnalytics(){
    const GOOGLE_ANALYTICS_ID = this.props.GOOGLE_ANALYTICS.ID;
    const FACEBOOK_PIXEL_ID = this.props.FACEBOOK_PIXEL.ID;


    if(GOOGLE_ANALYTICS_ID){
      document.addEventListener('turbo:load', () => {
        if(typeof gtag === 'function'){
          gtag('config', GOOGLE_ANALYTICS_ID, { 'page_path': window.location.pathname })
        }
      });
      insertScript(document.body, `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`, () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){ dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', GOOGLE_ANALYTICS_ID);
        window.gtag = gtag;
        fireEvent('analytics:gtag:loaded', null, { detail: { gtag: gtag }});
      });
    }
    if(FACEBOOK_PIXEL_ID){
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', FACEBOOK_PIXEL_ID);
      fbq('track', 'PageView');
      fireEvent('analytics:fb:loaded', null, { detail: { fbq: fbq }});
      window.fbq = fbq;
    }
  }

  _loadCookieConsent(){
    const loadAnalytics = this._loadAnalytics.bind(this);
    const COOKIE_CONSENT_PROPS = this.props.COOKIE_CONSENT;
    const callback = () => {
      window.cookieconsent.initialise({
        type: 'opt-in',
        theme: 'edgeless',
        position: 'bottom-left',
        content: {
           message: COOKIE_CONSENT_PROPS.MESSAGE_TEXT,
           link: COOKIE_CONSENT_PROPS.LINK_TEXT,
           allow: COOKIE_CONSENT_PROPS.ALLOW_TEXT,
           deny: COOKIE_CONSENT_PROPS.DENY_TEXT,
           href: COOKIE_CONSENT_PROPS.HREF,
        },
        onInitialise: function(status) {
          if(status == cookieconsent.status.allow){
            loadAnalytics();
          }
        },
        onStatusChange: function(status) {
          if(this.hasConsented()){
            loadAnalytics();
          }
        }
      });
    };
    insertScript(document.body, 'https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.1/cookieconsent.min.js', callback);
  }

  // _loadScriptFacebook(){
  //   !function(f,b,e,v,n,t,s)
  //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //   n.queue=[];t=b.createElement(e);t.async=!0;
  //   t.src=v;s=b.getElementsByTagName(e)[0];
  //   s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
  //   fbq('init', Env.FACEBOOK_PIXEL_ID);
  //   fbq('track', 'PageView');
  //   window.fbq = fbq
  //   fireEvent('analytics:fbq:loaded', null, { detail: { fbq: fbq }})
  // }

  // _loadScriptDrift(){
  //   var t = window.driftt = window.drift = window.driftt || [];
  //   if (!t.init) {
  //     if (t.invoked) return void (window.console && console.error && console.error('Drift snippet included twice.'));
  //     t.invoked = !0, t.methods = [ 'identify', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', 'on' ],
  //     t.factory = function(e) {
  //       return function() {
  //         var n = Array.prototype.slice.call(arguments);
  //         return n.unshift(e), t.push(n), t;
  //       };
  //     }, t.methods.forEach(function(e) {
  //       t[e] = t.factory(e);
  //     }), t.load = function(t) {
  //       var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement('script');
  //       o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js';
  //       var i = document.getElementsByTagName('script')[0];
  //       i.parentNode.insertBefore(o, i);
  //     };
  //   }
  //   drift.SNIPPET_VERSION = '0.3.1';
  //   drift.load('sm6rsgzcryxf');
  // }


}
