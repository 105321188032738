import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {

  static targets = ['player', 'cover']; 

  connect(){
    const interactions = ['mousemove', 'click', 'scroll', 'tap']
    interactions.map((interaction) => {
      document.addEventListener(interaction, () => {
        this.coverTarget.classList.add('hidden')
        this.playerTarget.classList.remove('hidden')
      }, { once: true })
    })
    // console.log(this.playerTarget)
  }

}
