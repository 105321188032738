import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static values = {
    content: String
  }

  connect() {
    let options = {}
    if (this.hasContentValue) {
      options['content'] = this.contentValue
    }
    this.tippy = tippy(this.element, options);
    this.element.classList.add('cursor-help');
  }

  disconnect() {
    this.tippy.destroy();
  }
}