import { Controller } from '@hotwired/stimulus'; 
import { tns } from 'tiny-slider'; 


export default class extends Controller {

  static targets = []; 
  static values = {
    options: Object
  }

  initialize() {
    const defaults = {
      container: this.element,
      touch: true
    }; 
    const args = Object.assign(defaults, this.optionsValue); 
    this.carousel = tns(args); 
    this.carousel.events.on('indexChanged', () => {
    });
    this.element.classList.remove('carousel--loading'); 
    this.element.dataset.behaviorLoaded = true; 
  }

  // _roundCssTransformMatrix(el){
  //   el.style.transform = '';
  //   let mx = window.getComputedStyle(el, null);
  //   if(mx){
  //     mx = mx.getPropertyValue('-webkit-transform') ||
  //       mx.getPropertyValue('-moz-transform') ||
  //       mx.getPropertyValue('-ms-transform') ||
  //       mx.getPropertyValue('-o-transform') ||
  //       mx.getPropertyValue('transform') || false;

  //     let values = mx.replace(/ |\(|\)|matrix/g,'').split(',');
  //     for(const v in values) { values[v] = v > 4 ? Math.ceil(values[v]) : values[v] }
  //     el.style.transform = 'matrix(' + values.join() + ')'; 
  //   }
  // }

  // _boot(){
  //
  //
  //
  //
  //   }
  // }

}
